// import imageCompression from 'browser-image-compression'
// import heic2any from "heic2any"


class GeneralHelper {
    //note: low quality, might not ensure randomness
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    
    //for android notification, uuid does not work, so use a simpler id instead (need to fit with int)
    uuidNotification() {
      return Math.floor(100000 + Math.random() * 900000);
    }

    localStoragePolyfill() {
        window.localStorage = {
          getItem: function (sKey) {
            if (!sKey || !this.hasOwnProperty(sKey)) { return null; }
            return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
          },
          key: function (nKeyId) {
            return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, "").split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
          },
          setItem: function (sKey, sValue) {
            if(!sKey) { return; }
            document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
            this.length = document.cookie.match(/\=/g).length;
          },
          length: 0,
          removeItem: function (sKey) {
            if (!sKey || !this.hasOwnProperty(sKey)) { return; }
            document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            this.length--;
          },
          hasOwnProperty: function (sKey) {
            return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
          }
        };
        window.localStorage.length = (document.cookie.match(/\=/g) || window.localStorage).length;
        return;
    }

    makeid(length = 20) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < length; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    hash(limit = 6)
    {
        const date = new Date();
        const year = String(date.getFullYear());
        const month = String(date.getMonth());
        const day = String(date.getDay());
        const sec = String(date.getMilliseconds());
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        for (let i = 0; i < limit; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        const str = year + month + day + sec + "-" + text;
        return str;
    }

    randomNo(limit = 4)
    {
      let text = ""
      const possible = "0123456789"
      for (let i = 0; i < limit; i++){
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text
    }

    parseUrlQuery(targetParam = '', promise = false) {
        const urlParams = new URLSearchParams(window.location.search);
        if(promise)
        {
          if(targetParam)
          {
            return urlParams.get(targetParam);
          }
          return new Promise(res => {
            return res(urlParams);
          });
        }
        if(targetParam)
        {
          return urlParams.get(targetParam);
        }
        return urlParams;
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
      
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
      
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
      
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
      
            var byteArray = new Uint8Array(byteNumbers);
      
            byteArrays.push(byteArray);
        }
      
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }


    dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    }
    
    removeWhiteSpace(string = '')
    {
      return string.replace(/^\s+|\s+$/g, "")
    }

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
      
        bytes.forEach((b) => binary += String.fromCharCode(b));
      
        return window.btoa(binary);
    }


    trimText(input = '')
    {
      switch(typeof input)
      {
        case 'object':
          let newObj = {}
          Object.keys(input).map(key => {
            const targetValue = input[key].trim()
            newObj[key] = targetValue
          })
          return newObj
        case 'array':
          let newArr = []
          input.forEach(item => {
            newArr.push(item.trim())
          })
          return newArr
        case 'string':
        default:
          return input.trim()
      }
    }

    capitalizeFirstLetter(input) { 
      const newArr = input.split(' ')
      let finalArr = []
      newArr.forEach(item => {
        const firstLetter = item[0].toUpperCase()
        const restLetter = item.slice(1).toLowerCase()
        const newStr = firstLetter+restLetter
        finalArr.push(newStr)
      })
      return finalArr.join('')
    }

    lowerFirstLetterAndCombineTheSpace(input) {
      const finalArr = input.split(' ')
      // let finalArr = []
      // newArr.forEach(item => {
      //   finalArr.push(item)
      // })
      let newStr = finalArr.join('')
      const firstLetter = newStr[0].toLowerCase()
      const restLetter = newStr.slice(1)
      const finalStr = firstLetter + restLetter
      return finalStr
    }

    capitalizeSentenceFirstLetter(input) {
      const firstLetter = input[0].toUpperCase()
      const restLetter = input.slice(1).toLowerCase()
      // const newStr = 
      return firstLetter + restLetter
    }

    // async compressImage(file)
    // {
    //   const options = { 
    //     maxSizeMB: 1.5,          // (default: Number.POSITIVE_INFINITY)
    //     maxWidthOrHeight: 768,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    //     useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    //     maxIteration: 10        // optional, max number of iteration to compress the image (default: 10)
    //   }

    //   try
    //   {
    //     const compressedFile = await imageCompression(file, options)
    //     return compressedFile
    //   }
    //   catch(err)
    //   {
    //     return false
    //   }
    // }

    // async convertHEICtoJpeg(file) {
    //   let inputBlob = await heic2any({
    //     // required: the HEIF blob file
    //     blob: file,
    //     // (optional) MIME type of the target file
    //     // it can be "image/jpeg", "image/png" or "image/gif"
    //     // defaults to "image/png"
    //     toType: "image/jpeg",
    //     // conversion quality
    //     // a number ranging from 0 to 1
    //     quality: 1
    //   })
    //   inputBlob.name = file.name
    //   inputBlob.lastModifiedDate = new Date()
    //   return new File([inputBlob], file.name, {type: 'image/jpeg', lastModified: Date.now()})
    // }

    // async resetOrientation(file, name) {
    //   const srcOrientation = await imageCompression.getExifOrientation(file).then(r => r).catch(e => false)
    //   const srcBase64 = await imageCompression.getDataUrlFromFile(file).then(r => r).catch(e => false)
    //   if(!srcOrientation || !srcBase64) return false

    //   var img = new Image();    
    
    //   img.onload = async () => {
    //     var width = img.width,
    //         height = img.height,
    //         canvas = document.createElement('canvas'),
    //         ctx = canvas.getContext("2d");
    
    //     // set proper canvas dimensions before transform & export
    //     if (4 < srcOrientation && srcOrientation < 9) {
    //       canvas.width = height;
    //       canvas.height = width;
    //     } else {
    //       canvas.width = width;
    //       canvas.height = height;
    //     }
    
    //     // transform context before drawing image
    //     switch (srcOrientation) {
    //       case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
    //       case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
    //       case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
    //       case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
    //       case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
    //       case 7: ctx.transform(0, -1, -1, 0, height, width); break;
    //       case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
    //       default: break;
    //     }
    
    //     // draw image
    //     ctx.drawImage(img, 0, 0);
    
    //     // export base64
    //     return canvas.toDataURL()
    //   };
      
    //   return img.src = this.dataURLtoFile(srcBase64, name)
    // }

    async convertBlobToBase64(blob)
    {
      const fr = new FileReader()
      // console.log("convert blob to file")
      fr.readAsDataURL(blob)
      fr.onload = (e) => {
          // console.log(e.target.result)
          return e.target.result
      }
    }

    fileToBase64(file)
    {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    }

    fileToBuffer(file)
    {
      return new Promise((res, rej) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => res(reader.result)
        reader.onerror = err => rej(err)
      })
    }

    // map the key/value pair of two JSON objects, and update the old object with the given new key/value pair
    joinObjs(toUpdate, newObj)
    {
      let newToUpdate = {...toUpdate}
      Object.keys(newObj).map((k, i) => {
        newToUpdate[k] = newObj[k]
      })
      return newToUpdate
    }

    validateEmail(mail) 
    {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
      {
        return true
      }
        // alert("You have entered an invalid email address!")
        return false
    }

    serializeGetUrl(obj) {
      var str = [];
      for(var p in obj)
         str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      return str.join("&");
    }

    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
  
      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','
  
              line += array[i][index];
          }
  
          str += line + '\r\n';
      }
  
      return str;
  }
  
  exportCSVFile(headers, items, fileTitle) {
      if (headers) {
          items.unshift(headers);
      }
  
      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);
  
      var csv = this.convertToCSV(jsonObject);
  
      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
  
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
  }
  

}
export default new GeneralHelper()
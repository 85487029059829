import AuthRequestHelper from "./AuthRequestHelper"
import GeneralHelper from "./GeneralHelper"
//NOTE: SEE ../Docs/questionnaireJSONDesign.md and ../Docs/questionnaireJSONDesign.md for the json format used in the questionnaire

class QuestionnaireHelper {
    /***************ALL GET/FETCH REQUESTS ****/
    /***************        get            ****/
    /******************************************/

    // WARNING: only allow this features to the admin
    async getAllQuesEntries() {
        try {
            const requestUrl = "getAllQuesEntry"
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            // return {}
            return false
        }
    }

    //get questionnaire user by questionnaire user id
    async getQuesUser(id) {
        try {
            const requestUrl = "quesuser/" + id
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    //get all users belonging to a questionnaire group
    async getAllQuesUserByGroupID(groupId) {
        try {
            const requestUrl = "quesuserByGroupId/" + groupId
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    //get questionnaire user group by user group id
    async getQuesUserGroup(id) {
        try {
            const requestUrl = "quesusergroup/" + id
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    //combination of get user and group
    async getAllQuesUserInfo(id) {
        try {
            let returnData = {}
            let roleData = await this.getQuesUser(id)
            returnData = { ...roleData }

            let groupData = await this.getQuesUserGroup(returnData.group_id)
            returnData = { ...groupData }
            //try parsing group object 
            if (returnData.hasOwnProperty("group_opt_data")) {
                returnData["group_opt_data"] = JSON.parse(returnData["group_opt_data"])
            }

            return returnData
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    //get all the questionnaire ids
    async getAllQuesInfo() {
        try {
            const requestUrl = "quesinfo/"
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    async getAllQuesContentInfoI() {
        try {
            const requestUrl = "getAllQuesInfo/"
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    //get questionnaire structure by questionnaire Info id
    async getQuesInfo(id) {
        try {
            const requestUrl = "quesinfoByQuesId/" + id
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }



    //get questionnaire Entry by ques entry id
    async getQuesEntry(id) {
        try {
            const requestUrl = "quesentry/" + id
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    //get all questionnaire entries by user id 
    async getAllQuesEntryByUserID(userID) {
        try {
            const requestUrl = "quesentryByUserId/" + userID
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }


    //get all questionnaire entries by user id 
    async getAllQuesEntryByQuesID(QuesID) {
        try {
            const requestUrl = "quesentryByQuesId/" + QuesID
            let roleData = await AuthRequestHelper.request("get", requestUrl, {})
            return roleData.data
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

    /***************ALL PUT REQUESTS       ****/
    /*************      PUT                ****/
    /******************************************/
    async addQuesUserByNationalID(groupId, nationalID, otherOptData = {}, uuid = GeneralHelper.uuidv4()) {
        console.log(`added group id ${groupId}, national id ${nationalID}`)
        try {
            const requestUrl = "addquesuser"
            const quesUserParams = {
                "uuid": uuid,
                "group_id": groupId,
                "user_opt_data": JSON.stringify({
                    "nationalID": nationalID,
                    ...otherOptData
                })
            }
            return await AuthRequestHelper.request("put", requestUrl, quesUserParams)
        }
        catch (error) {
            console.log(error)
            return ""
        }
    }

    /*Add Questionnaire User to the online server */
    /*Note: This might be depreceated */
    async addQuesUser(groupId, userId = GeneralHelper.uuidv4(), userOptData) {
        try {
            const requestUrl = "addquesuser"
            const quesUserParams = {
                "uuid": userId,
                "group_id": groupId,
                "user_opt_data": JSON.stringify({
                    ...userOptData
                })
            }
            return await AuthRequestHelper.request("put", requestUrl, quesUserParams)
        }
        catch (error) {
            console.log(error)
            return ""
        }
    }

    /*Add Questionnaire User Group by group name*/
    async addQuesUserGroupByName(grouplevel1, grouplevel2) {
        console.log(`input group id ${grouplevel1}, subgroup id ${grouplevel2}`)
        try {
            const requestUrl = "addquesusergroupByGroupName"
            const quesUserGroupParams = {
                "group_name_l1": grouplevel1,
                "group_name_l2": grouplevel2
            }
            return await AuthRequestHelper.request("put", requestUrl, quesUserGroupParams)
        }
        catch (error) {
            console.log(error)
            return ""
        }
    }

    /*Add Questionnaire User Group */
    /*Note: This might be depreceated */
    async addQuesUserGroup(groupName, groupOptData) {
        try {
            const requestUrl = "addquesusergroup"
            const quesUserGroupParams = {
                "group_name": groupName,
                "group_opt_data": groupOptData
            }
            return await AuthRequestHelper.request("put", requestUrl, quesUserGroupParams)
        }
        catch (error) {
            console.log(error)
            return ""
        }
    }

    /*Add Questionnaire info */
    async addQuesInfo(qMetaData) {
        try {
            const requestUrl = "addquesinfo"
            const quesInfoParams = {
                "q_meta_data": qMetaData
            }
            return await AuthRequestHelper.request("put", requestUrl, quesInfoParams)
        }
        catch (error) {
            console.log(error)
            return ""
        }
    }

    /*
    Add Questionnaire Entry 
    use stringify json for qData
    */
    async addQuesEntry(qData, qId, userID) {
        console.log(`received user id is : ${userID}`)
        try {
            const requestUrl = "addquesentry"
            const quesEntryParams = {
                "q_data": qData,
                "q_id": qId,
                "user_id": userID,
            }
            return await AuthRequestHelper.request("put", requestUrl, quesEntryParams)
        }
        catch (error) {
            console.log(error)
            return ""
        }
    }

    /******************************************/
    /*************  EXPORT REQUESTS        ****/
    /******************************************/
    async getQuesExcel(id) {
        try {
            const requestUrl = "quesentryExportExcel/" + id
            const filename = "test.xlsx" //create name yourself
            await AuthRequestHelper.requestFile("get", requestUrl, {}, filename)
            return true
        }
        catch (error) {
            console.log(error)
            return {}
        }
    }

}

export default new QuestionnaireHelper();
import React,{useState,useEffect,useMemo}  from 'react'
import { bindActionCreators } from '@reduxjs/toolkit';
import {connect} from 'react-redux'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import * as UserActions from '../reducers/slices/user'
import * as ConfigActions from '../reducers/slices/config'

import DashboardRoutes from '../routes/DashboardRouter';


import DashboardHelper from '../helpers/DashboardHelper'
import UserStatusHelper from '../helpers/UserStatusHelper'
import QuestionnaireHelper from '../helpers/QuestionnaireHelper'
import methods from '../methods';

let Home = props => {

  useEffect(()=>{

    // window.location.replace("/survey")
    //try to access username if it exists
    const testFetches = async ()=>{
        //test Lime
        //let surveyResults = await LimeHelper.getSurvey("441461")
        //console.log(surveyResults)

        //test DASHBOARD LOGIN
        // let results = await AuthenticationHelper.login("user","user");
        // let userRole= await UserStatusHelper.getUserRole(AuthenticationHelper.uuid) 
        // console.log(userRole)

        // //test Questionnaire FETCHS
        // let quser = await QuestionnaireHelper.getQuesUser("5aae9cc1-4022-4013-a780-27ffa9729558");
        // console.log("test get questionnaire user")
        // console.log(quser)
        // let quserGroup = await QuestionnaireHelper.getQuesUserGroup("68058f31-21c9-455c-8a67-a1be177e5336");
        // console.log("test get questionnaire user group")
        // console.log(quserGroup)
        // let qInfoIds = await QuestionnaireHelper.getAllQuesInfo(); //need to change questionnaire info id
        // console.log("test get all questionaire info id")
        // console.log(qInfoIds)
        // let qInfo = await QuestionnaireHelper.getQuesInfo("6f54b73b-08f3-4c22-8eed-c053282cee6e"); //need to change questionnaire info id
        // console.log("test get a questionaire info")
        // console.log(qInfo)
        // let qEntry = await QuestionnaireHelper.getQuesEntry("b4f15d07-075e-4ffc-9cda-8effb0538f51");
        // console.log("test get a questionaire info entry")
        // console.log(qEntry)

        // let qEntryAllUser = await QuestionnaireHelper.getAllQuesEntryByUserID("5aae9cc1-4022-4013-a780-27ffa9729558");
        // console.log("test get all questionaire entry by user id")
        // console.log(qEntryAllUser)
        // let qEntryAllQues = await QuestionnaireHelper.getAllQuesEntryByQuesID("6f54b73b-08f3-4c22-8eed-c053282cee6e"); //need to change questionnaire info id
        // console.log("test get all questionaire entry by questionnaire id")
        // console.log(qEntryAllQues)

        //test Questionnaire PUTS
        /*
        let addqUser= await QuestionnaireHelper.addQuesUser("68058f31-21c9-455c-8a67-a1be177e5336",{'name': 'John Smith'})
        console.log(addqUser)
        let addqUserGroup= await QuestionnaireHelper.addQuesUserGroup("test Group 1",{'group_lv1_category': 'Defense', 'group_lv2_category': 'Army'})
        console.log(addqUserGroup)
        let addqInfo= await QuestionnaireHelper.addQuesInfo(
          {
            "q1":{"type":"likert","category":"c1","title":"How corrupt do you feel this organization is?","opt_data":{}}, 
            "q2":{"type":"likert","category":"c1","title":"How corrupt do others view your organization","opt_data":{}},
            "q3":{"type":"categorical","category":"","title":"What is your educational level?","opt_data":{}},
            "q4":{"type":"YesNo","category":"","title":"Did your organization adhere to ethical standards?","opt_data":{}}
            }
        )
        console.log(addqInfo)
        let addqEntry= await QuestionnaireHelper.addQuesEntry(
          {
            "q1":3, 
            "q2":4,
            "q3":"bachelor's degree",
            "q4":true
          },
          "0290c736-d9d8-4149-b602-fb25f16e67e8",
          "5aae9cc1-4022-4013-a780-27ffa9729558"
        )
        console.log(addqEntry)
        */
        
        //test file download
        //await QuestionnaireHelper.getQuesExcel("3eb386e0-350a-436a-9d43-0093c8d081f2")

        //test fetch dashboard (sub compared with main)
        // let dashresult= await DashboardHelper.getDashboard("22dbf163-5a23-4fdb-a4ba-c7378a3082a7","กระทรวงกลาโหม","ข้าราชการการเมือง")
        // console.log('%c'+JSON.stringify(dashresult), 'background: red; color: white; padding: 3px; border-radius: 5px;');

        //test fetch dashboard (main compared with others)
        // let dashresultAll= await DashboardHelper.getDashboardComparedAll("22dbf163-5a23-4fdb-a4ba-c7378a3082a7","ข้าราชการการเมือง")
        // console.log('%c'+JSON.stringify(dashresultAll), 'background: green; color: white; padding: 3px; border-radius: 5px;');

        //test current dashboard user info
        // let userInfo=await UserStatusHelper.getAllQuesUserInfo("025bbfc8-102f-4730-9a56-6476ff928330")
        // console.log('%c'+JSON.stringify(userInfo), 'background: blue; color: white; padding: 3px; border-radius: 5px;');
    }

    testFetches();
},[props.location]);

  const {user, config} = props

  // console.log("check here", props)
  return <IonPage>
    <DashboardRoutes />
  </IonPage>
  // return (
  //   <IonPage>
  //     <IonHeader>
  //       <IonToolbar>
  //         <IonTitle>Blank</IonTitle>
  //       </IonToolbar>
  //     </IonHeader>
  //     <IonContent fullscreen>
  //       <IonHeader collapse="condense">
  //         <IonToolbar>
  //           <IonTitle size="large">Blank</IonTitle>
  //         </IonToolbar>
  //       </IonHeader>
  //       <ExploreContainer />
  //     </IonContent>
  //   </IonPage>
  // );
};
/**
 * this binds all the action creators related to userReducer
 */
 let HomePageContainer = props => {
  const { dispatch, user, config } = props


  const bountUserActionCreators = useMemo(() => bindActionCreators({...UserActions, ...ConfigActions, resetSession: methods.RESET_SESSION_ACTION}, dispatch), [dispatch])
  // const bountConfigActionCreators = useMemo(() => bindActionCreators(ConfigActions, dispatch), [dispatch])

  // console.log("home container props", bountUserActionCreators)
  return <Home user={user} config={config} {...bountUserActionCreators} {...props} />
}

HomePageContainer = connect(state => ({ user: state.userReducer, config: state.configReducer }))(HomePageContainer)

export default HomePageContainer

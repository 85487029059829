import React, { useContext, createContext, useState, useMemo } from "react"
import {
    useLocation,
    Redirect
} from "react-router-dom";
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch, connect } from 'react-redux'
import * as UserActions from '../reducers/slices/user'

let AuthContext = React.createContext(null);

let AuthProvider = (props) => {

    let value = { 
        user: props?.user || {},
        isAuthenticated: props?.user?.id && props?.user?.id !== "",
        ...props
    }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
// export default AuthProvider
let AuthProviderWrapper = props => {
    const { dispatch, user, config } = props
    const boundActionCreators = useMemo(
        () => bindActionCreators(UserActions, dispatch),
        [dispatch]
    )
    let isAuthenticated = user?.id && user?.id !== "" && user?.id !== null && user?.id !== undefined

//     return <AuthProvider user={user} {...boundActionCreators} {...props} />
    return <AuthProvider {...boundActionCreators} isAuthenticated={isAuthenticated} {...props} />
}

AuthProviderWrapper = connect(state => ({ user: state.userReducer, config: state.configReducer }))(AuthProviderWrapper)
export {AuthProviderWrapper}
/**
 * this binds all the action creators related to userReducer
 */
// AuthProvider = props => {
//     const { dispatch, user } = props

//     const boundActionCreators = useMemo(
//         () => bindActionCreators(UserActions, dispatch),
//         [dispatch]
//     )

//     return <AuthProvider user={user} {...boundActionCreators} {...props} />
// }

// export default connect(state => ({ user: state.userReducer, config: state.configReducer }))(AuthProvider)


export function useAuth() {
  return React.useContext(AuthContext);
}

let RequireAuth = (props) => {
    const auth = useAuth();
    let location = useLocation();
    // console.log("required auth prps", props)
    // console.log("auth props", auth)
    // console.log("from location", location)

    if (!auth.isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Redirect to="/auth/login" state={{ from: location }} replace />
    }
  
    return props.children;
}

// let RequireAuthWrapper = props => {
//     const { dispatch, user } = props
//     console.log('required auth props', props)

//     const boundActionCreators = useMemo(
//         () => bindActionCreators(UserActions, dispatch),
//         [dispatch]
//     )

//     return <RequireAuth user={user} isAuthenticated={user?.id && user?.id !== ''} {...boundActionCreators} {...props} />
// }
// RequireAuthWrapper = connect(state => ({ user: state.userReducer, config: state.configReducer }))(RequireAuthWrapper)
export {RequireAuth}
import React, { lazy } from 'react';
import { 
    BrowserRouter as Router,
    Route, 
    Switch 
} from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
// project imports
import LazyLoad from '../components/LazyLoad';

const Auth = LazyLoad(lazy(() => import('../pages/Auth')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = () => {

    return (<Switch>
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/auth/:subcomponent" component={Auth} />
    </Switch>)

}

export default AuthenticationRoutes;

import { createSlice } from '@reduxjs/toolkit'
import methods from '../../methods'

const initUser = {
    id: '',
    role: '',
    group: {
        id: '',
        group_lv1_category: '',
        group_lv2_category: ''
    }
}

const userReducer = createSlice({
    name: 'user',
    initialState: initUser,
    reducers: {
        login: (state, action) => {
            return action.payload
        },
        update: state => {

        },
        logout: state => {
            return initUser
        }
        //   increment: state => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        //   }
    },
    extraReducers: (builder) => {
        builder
            .addCase(methods.RESET_SESSION, (state, action) => {
                // console.log("user reducer receives action", action)
                state = initUser
            })
        // .addDefaultCase((state, action) => {
        //     state = initState
        // })
    },
})

// Action creators are generated for each case reducer function
export const { login, update, logout } = userReducer.actions

export default userReducer.reducer
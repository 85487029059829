import AuthRequestHelper from "./AuthRequestHelper"

class DashboardHelper{

    /*get dashboard */
    async getDashboard(quesID,mainGroupName,SubGroupName){
        try{
            const requestUrl="dashByQuesId/"+quesID
            const dashQuesParams={
                "main_group":mainGroupName,
                "compare_group":SubGroupName
            }
            let roleData=await AuthRequestHelper.request("post",requestUrl,dashQuesParams)   
            return roleData.data   
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    /*get dashboard compared with all */
    async getDashboardComparedAll(quesID,mainGroupName){
        try{
            const requestUrl="dashByQuesId/"+quesID
            const dashQuesParams={
                "main_group":mainGroupName,
                "compare_group":"All"
            }
            let roleData=await AuthRequestHelper.request("post",requestUrl,dashQuesParams)   
            return roleData.data   
        }
        catch(error){
            console.log(error)
            return {}
        }
    }   

}

export default new DashboardHelper();
import AuthRequestHelper from "./AuthRequestHelper"
import QuestionnaireHelper from "./QuestionnaireHelper"
class UserStatusHelper{

    /*get user role profile */
    async getUserRole(id){
        try{
            const requestUrl="userrole/"+id
            let roleData=await AuthRequestHelper.request("get",requestUrl,{})   
            return roleData.data   
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    //combination of get user and group info
    async getAllQuesUserInfo(id){
        try{
            let returnData={}
            let roleData=await this.getUserRole(id)  
            returnData={...roleData}

            let groupData=await QuestionnaireHelper.getQuesUserGroup(returnData.group_id)
            returnData={...groupData}
            
            //try parsing group object 
            if (returnData.hasOwnProperty("group_opt_data"))
            {
                returnData["group_opt_data"]=JSON.parse(returnData["group_opt_data"])
            }

            return returnData
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

}

export default new UserStatusHelper();
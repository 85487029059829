import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// project imports
import LazyLoad from '../components/LazyLoad';

const Dashboard = LazyLoad(lazy(() => import('../pages/Dashboard')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const DashboardRoutes = () => {

    return (
        <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/:subcomponent" component={Dashboard} />
        </Switch>
    )

}

export default DashboardRoutes;

import { createSlice } from '@reduxjs/toolkit'
// import lang from '../lang/initLang'
import initLang from '../../lang/initLang'
import methods from '../../methods'
import init from '../../config/init'

const initState = {
    lang: initLang.hasOwnProperty(init.defaultLang) ? initLang[init.defaultLang] : initLang.en
}

const configReducer = createSlice({
    name: 'config',
    initialState: initState,
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload
        },
        //   increment: state => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        //   }
    },
    extraReducers: (builder) => {
        builder
            .addCase(methods.RESET_SESSION, (state, action) => {
                // console.log("user reducer receives action", action)
                state = initState
            })
            // .addDefaultCase((state, action) => {
            //     state = initState
            // })
    },
})

// Action creators are generated for each case reducer function
export const { setLang } = configReducer.actions

export default configReducer.reducer
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const LazyLoad = (Component) => (props) =>
    (
        <Suspense fallback={<CircularProgress />}>
            <Component {...props} />
        </Suspense>
    );

export default LazyLoad;

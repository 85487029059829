import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import createStore from './createStore'
import { Provider } from 'react-redux'

const { store } = createStore()

window.store = store

store.subscribe(() => {
  const state = JSON.stringify(store.getState())
  localStorage.setItem(process.env.REACT_APP_REDUCER_STORAGE_KEY, state)
})

if(process.env.REACT_APP_ENV === "production")
{
  console.log = () => null
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// Note, not currently using service workers now 
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { combineReducers } from 'redux'
import methods from '../methods'
import userReducer from './slices/user'
import configReducer from './slices/config'
import questionnaireReducer from './slices/questionnaire'
import dashboardReducer from './slices/dashboard'

const appReducer = combineReducers({
    userReducer,
    configReducer,
    questionnaireReducer,
    dashboardReducer
})
  
const rootReducer = (state, action) => {
    // console.log("received action", action)
    if (action.type === 'RESET_SESSION') {
        // console.log("should reset", action)
        // console.log(appReducer(undefined, action))
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer
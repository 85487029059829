import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// project imports
import LazyLoad from '../components/LazyLoad';

const Survey = LazyLoad(lazy(() => import('../pages/Survey')))

const SurveyRoutes = () => {
    return <Switch>
        <Route exact path="/survey" component={Survey} />
        <Route path="/survey/:subcomponent" component={Survey} />
    </Switch>
}

export default SurveyRoutes
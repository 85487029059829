import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact, withIonLifeCycle, IonPage } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import 
  { AuthProviderWrapper as AuthProvider, 
    RequireAuth
    // RequireAuthWrapper as RequireAuth
  } 
from './hoc/AuthProvider'
import {Component} from 'react'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import AuthenticationRoutes from './routes/AuthenticationRoutes';
import SurveyRoutes from './routes/SurveyRoutes'

setupIonicReact();

class App extends Component {
  constructor(props)
  {
   super(props);
   this.state={
   }


 }
 render(){
  return(
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
        {/** 
         * this chunk of application consumes the same auth context 
         * I only use it to check if user has logged in.
         * The user credential is managed by redux primarily
        */}
        <AuthProvider>

          <Route exact path="/home">
            <RequireAuth>
              <Home />
            </RequireAuth>
          </Route>

          <Route exact path="/">
              <RequireAuth>
                <Home />
              </RequireAuth>
          </Route>
          <AuthenticationRoutes />
          <SurveyRoutes />
        </AuthProvider>
        </IonRouterOutlet>
    </IonReactRouter>
    </IonApp>)
  }
};
export default withIonLifeCycle(App);

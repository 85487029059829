import { createSlice } from '@reduxjs/toolkit'
import methods from '../../methods'

const initState = {
    accessLevel: "", //
    statsData: {}
}

const dashboardReducer = createSlice({
    name: 'dashboard',
    initialState: initState,
    reducers: {
        // selectSurvey: (state, action) => {
        //     state.selectedSurvey = action.payload
        // },
        setAccessLevel: (state, action) => {
            state.accessLevel = action.payload
        },
        loadStatsData: (state, action) => {
            state.statsData = action.payload
        },
        // loadSurveys: (state, action) => {
        //     Object.keys(action.payload).map(k => {
        //         let qMetaData = {}
        //         state.surveys[k] = action.payload[k]
        //         try
        //         {
        //             // the q meta data might be stringifed JSON object. so parse it if it is a string
        //             if(typeof action.payload[k]['q_meta_data'] === 'string')
        //             {
        //                 qMetaData = JSON.parse(action.payload[k]['q_meta_data'])
        //                 state.surveys[k]['q_meta_data'] = qMetaData
        //             }

        //         }
        //         catch(e)
        //         {

        //         }

        //     })
        // },
        // addSurveyEntry: (state, action) => {
        //     let surveyId = action.payload.surveyId
        //     try
        //     {
        //         if(!state.surveyEntries.hasOwnProperty(surveyId))
        //         {
        //             state.surveyEntries[surveyId] = {}
        //         }
        //         const qKey = action.payload.qKey
        //         const answer = action.payload.answer
        //         state.surveyEntries[surveyId][qKey] = answer
        //     }
        //     catch(e)
        //     {

        //     }

        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(methods.RESET_SESSION, (state, action) => {
                // console.log("user reducer receives action", action)
                state = initState
            })
            // .addDefaultCase((state, action) => {
            //     state = initState
            // })
    },
})

export const {
    setAccessLevel,
    loadStatsData
} = dashboardReducer.actions

export default dashboardReducer.reducer
const config = {
    localAuth: {
        apiServiceUrl: process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_LOCAL_API_HOST:process.env.REACT_APP_API_HOST,
        //apiServiceUrl: 'https://dashboardapi.sipanote.com/api/'
    },
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENV
}


export default config
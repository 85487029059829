import { createSlice } from '@reduxjs/toolkit'
import methods from '../../methods'

const initState = {
    surveyIds: [],
    surveys: {},
    selectedSurvey: 0,
    surveyEntries: {}
}

const questionnaireReducer = createSlice({
    name: 'questionnaire',
    initialState: initState,
    reducers: {
        selectSurvey: (state, action) => {
            state.selectedSurvey = action.payload
        },
        loadSurveyIds: (state, action) => {
            state.surveyIds = action.payload
        },
        loadSurveys: (state, action) => {
            Object.keys(action.payload).map(k => {
                let qMetaData = {}
                state.surveys[k] = action.payload[k]
                try {
                    // the q meta data might be stringifed JSON object. so parse it if it is a string
                    if (typeof action.payload[k]['q_meta_data'] === 'string') {
                        qMetaData = JSON.parse(action.payload[k]['q_meta_data'])
                        state.surveys[k]['q_meta_data'] = qMetaData
                    }

                }
                catch (e) {

                }

            })
        },
        clearSurveyIds: (state) => {
            state.surveyIds = [...initState.surveyIds]
        },
        clearSurveys: (state) => {
            state.surveys = { ...initState.surveys }
        },
        addSurveyEntry: (state, action) => {
            let surveyId = action.payload.surveyId
            try {
                if (!state.surveyEntries.hasOwnProperty(surveyId)) {
                    state.surveyEntries[surveyId] = {}
                }
                const qKey = action.payload.qKey
                const answer = action.payload.answer
                state.surveyEntries[surveyId][qKey] = answer
            }
            catch (e) {

            }
        },
        removeSurveyEntry: (state, action) => {
            console.log("remove called", action.payload)
            let surveyId = action.payload.surveyId
            try {
                if (!state.surveyEntries.hasOwnProperty(surveyId)) {
                    state.surveyEntries[surveyId] = {}
                }
                const qKey = action.payload.qKey
                console.log("to remove ", qKey)
                // const answer = action.payload.answer
                // state.surveyEntries[surveyId][qKey] = answer
                delete state.surveyEntries[surveyId][qKey]
                console.log("removed", state.surveyEntries[surveyId])
            }
            catch (e) {

            }
        },
        clearSurveyEntries: (state, action) => {
            let surveyId = action.payload.surveyId
            try {
                if (!state.surveyEntries.hasOwnProperty(surveyId)) {
                    return
                }
                // const answer = action.payload.answer
                // state.surveyEntries[surveyId][qKey] = answer
                delete state.surveyEntries[surveyId]
            }
            catch (e) {

            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(methods.RESET_SESSION, (state, action) => {
                // console.log("user reducer receives action", action)
                state = initState
            })
        // .addDefaultCase((state, action) => {
        //     state = initState
        // })
    },
})

export const {
    selectSurvey,
    loadSurveyIds,
    loadSurveys,
    clearSurveys,
    clearSurveyIds,
    addSurveyEntry,
    removeSurveyEntry,
    clearSurveyEntries
} = questionnaireReducer.actions

export default questionnaireReducer.reducer